<template>
  <div class="syllabus-lists">
    <div class="container">
      <div class="syllabus-cell" :class="item.isShow?'active':''" :data-num="index" v-for="(item,index) in Data" :key="index">
        <div class="syl-button">{{item.part}}</div>
        <div class="flex-col">
          <div class="syl-head">
            <div class="syl-title">{{item.title}}</div>
            <div class="syl-tag" v-if="item.tag">{{item.tag}}</div>
            <div class="syl-attr">{{item.attr}}</div>
          </div>
          <div class="syl-grid">
            <div class="syl-item">{{item.itemdesc}}</div>
            <div class="syl-item text-org">{{item.plan}}</div>
          </div>
          <div class="syl-arrow" @click="toggle(index)">
            <span class="tt" v-if="!item.isShow">展开列表</span><span class="tt" v-else>收起列表</span><span class="arrow"></span>
          </div>
          <div class="syl-list" v-if="item.isShow">
            <div class="syl-list-cell" v-for="(subitem,index) in item.subDate" :key="index">
              <div class="hd">{{subitem.tit}}</div>
              <div class="list-text" v-for="(thirditem,index) in subitem.klist" :key="index">{{thirditem.listitem}}</div>
            </div>
          </div>
          <div class="syl-foot" v-if="item.isShow" @click="toggle(index)">
            <span class="arrow-up"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "courseDetail",
  props: {
    TabActive: 0,
    courseID: "",
  },
  data() {
    return {
      Data:[
        {
          part:'第一阶段',
          title:'预科语言',
          itemdesc:'Java语言零基础入门，3天即可搞定编程语言，简单高效。',
          plan:'课程安排：1-3天即可入门，每天1小时',
          tag:"可选",
          isShow: false,
          subDate:[
            {
              tit:"Java 零基础快速入门",
              klist:[
                {
                  listitem:'Java介绍',
                },
                {
                  listitem:'Java基础语法',
                },
                {
                  listitem:'Java特性',
                },
                {
                  listitem:'异常处理',
                }
              ]
            }
          ],
          attr:'1-3天'
        },
        {
          part:'第二阶段',
          title:'算法体系',
          itemdesc:'算法基础知识，题型技巧总结，LeetCode 千题视频讲解，2个月400题， 3个月500题， 横扫所有公司算法面试。',
          plan:'课程安排：2+1个月，每周50题左右，2个月学习，1个月复习，工作日每天2-3小时，周末5-6小时',
          isShow: false,
          subDate:[
            {
              tit:"第一周",
              klist:[
                {
                  listitem:'算法，时间&空间复杂度',
                },
                {
                  listitem:'算法必备基础',
                },
                {
                  listitem:'位运算',
                },
                {
                  listitem:'数学题',
                }
              ]
            },{
              tit:"第二周",
              klist:[
                {
                  listitem:'数组',
                },
                {
                  listitem:'基于比较的排序算法',
                },
                {
                  listitem:'非比较的排序算法',
                },
              ]
            },{
              tit:"第三周",
              klist:[
                {
                  listitem:'二分查找',
                },
                {
                  listitem:'链表',
                },
                {
                  listitem:'随机',
                },
              ]
            },{
              tit:"第四周",
              klist:[
                {
                  listitem:'栈',
                },
                {
                  listitem:'队列',
                },
                {
                  listitem:'字符串',
                },
              ]
            },{
              tit:"第五周",
              klist:[
                {
                  listitem:'面试技巧指导',
                }
              ]
            },{
              tit:"第六周",
              klist:[
                {
                  listitem:'散列表',
                },
                {
                  listitem:'堆',
                },
                {
                  listitem:'图形',
                },
              ]
            },{
              tit:"第七周",
              klist:[
                {
                  listitem:'图',
                },
                {
                  listitem:'拓扑排序',
                },
                {
                  listitem:'并查集',
                },
                {
                  listitem:'回溯法',
                },
              ]
            },{
              tit:"第八周",
              klist:[
                {
                  listitem:'树',
                },
                {
                  listitem:'最小生成树 & 最短路径',
                },
                {
                  listitem:'字典树 & 线段树 & 树桩数组',
                },
              ]
            },{
              tit:"第九周",
              klist:[
                {
                  listitem:'红黑树 & AVL树',
                },
                {
                  listitem:'贪心算法',
                },
                {
                  listitem:'设计',
                },
                {
                  listitem:'动态规划',
                },
              ]
            },{
              tit:"第十周",
              klist:[
                {
                  listitem:'简历修改指导',
                },
              ]
            }
          ],
          attr:'2+1个月'
        },
        {
          part:'第三阶段',
          title:'设计体系',
          itemdesc:'23种设计模式，\b面向对象设计OOD，系统设计System Design，体系化设计能力全面提升',
          plan:'课程安排：1-2个月，算法体系复习月开始，工作日每天1-2小时，周末2-3小时',
          isShow: false,
          subDate:[
            {
              tit:"第一周",
              klist:[
                {
                  listitem:'23种设计模式（1）',
                },
                {
                  listitem:'算法二刷',
                },
                {
                  listitem:'模拟面试 1',
                }
              ]
            },{
              tit:"第二周",
              klist:[
                {
                  listitem:'23种设计模式（2）',
                },
                {
                  listitem:'算法二刷',
                }
              ]
            },{
              tit:"第三周",
              klist:[
                {
                  listitem:'23种设计模式（3）',
                },
                {
                  listitem:'算法二刷',
                }
              ]
            },{
              tit:"第四周",
              klist:[
                {
                  listitem:'面向对象设计OOD（1）',
                },
                {
                  listitem:'算法二刷',
                },
                {
                  listitem:'模拟面试 2',
                }
              ]
            }, {
              tit:"第五周",
              klist:[
                {
                  listitem:'面向对象设计OOD（2）',
                },
                {
                  listitem:'算法二刷',
                },
              ]
            },{
              tit:"第六周",
              klist:[
                {
                  listitem:'系统设计 System Design（1）',
                },
                {
                  listitem:'算法三刷',
                },
              ]
            },{
              tit:"第七周",
              klist:[
                {
                  listitem:'系统设计 System Design（2）',
                },
                {
                  listitem:'算法三刷',
                },
              ]
            },{
              tit:"第八周",
              klist:[
                {
                  listitem:'系统设计 System Design（3）',
                },
                {
                  listitem:'算法三刷',
                },
                {
                  listitem:'模拟面试 3',
                }
              ]
            },
          ],
          attr:'1-2个月'
        },
        {
          part:'第四阶段',
          title:'工业级项目',
          itemdesc:'针对学生阶段，转专业，或者简历水项目，进行工业级项目，充实简历，过简历关',
          plan:'项目安排：1-2个月，2周一个，1-4个项目',
          isShow: false,
          tag:"可选",
          subDate:[
            {
              tit:"服务内容",
              klist:[
                {
                  listitem:'工业级硬核项目',
                },
                {
                  listitem:'框架为核心',
                },
                {
                  listitem:'根据学生简历需求调整个数',
                },
                {
                  listitem:'如有需求，可以先做项目，调整优先级别',
                },
                {
                  listitem:'1个月速成4-5个项目，迅速过简历关参加面试',
                }
              ]
            }
          ],
          attr:'1-2个月'
        },
        {
          part:'第五阶段',
          title:'内推强推',
          itemdesc:'水平达到面试水准的学员，10家公司起步，FMAG等大厂全部包括',
          plan:'如特别优秀学员，可进行强推，终身内推',
          isShow: false,
          subDate:[
            {
              tit:"服务内容",
              klist:[
                {
                  listitem:'Senior级别内推',
                },
                {
                  listitem:'国内外公司都可以推',
                },
                {
                  listitem:'强推省掉电面',
                },
                {
                  listitem:'终身内推',
                }
              ]
            }
          ],
          attr:'10+公司 ｜ 终身内推'
        },
        {
          part:'最后阶段',
          title:'全程1对1服务体系',
          itemdesc:'以1对1为主，全程以“人”为中心服务，可以随时找到讲课导师；可根据学生情况调整阶段顺序',
          plan:'Edward导师电话沟通',
          isShow: false,
          subDate:[
            {
              tit:"服务内容",
              klist:[
                {
                  listitem:'1对1求职规划',
                },
                {
                  listitem:'1对1项目课程',
                },
                {
                  listitem:'1对1电话监督',
                },
                {
                  listitem:'1对1课后答疑',
                },
                {
                  listitem:'1对1简历修改',
                },
                {
                  listitem:'1对1模拟面试',
                }
              ]
            }
          ],
          attr:'全程1v1服务'
        }
      ]
    };
  },
  mounted() {},
  created() {},
  watch: {},
  methods: {
    toggle(index) {
      var isShow = this.Data[index].isShow;
      this.Data[index].isShow = isShow === true ? false : true;
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/courses/common.css");
.syllabus-cell{
  position: relative;
}
.syl-foot{
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 40px;
  padding-right:150px
}
.syl-foot .arrow-up{
  display: inline-block;
  width: 70px;
  height: 40px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  background:#ffe6de url(../../assets/images/beimeivip/arrow-up.png) center no-repeat;
}


/* 课程大纲 */
.syllabus-lists{
	padding: 10px 0;
}
.syllabus-cell{
	display: flex;
	padding: 30px 0;
	line-height: 1.4;
	border-bottom: 1px solid #E5E5E5;
}
.flex-col{
	flex: 1;
	min-width: 0;
}
.syl-button{
	width: 132px;
	height: 31px;
	line-height: 31px;
	text-align: center;
	color: #ffffff;
	font-size: 16px;
	margin-right: 20px;
	background: url(../../assets/images/beimeivip/UMLspan_on.png) no-repeat;
}
.active .syl-button{
	color: #FF602A;
	background: url(../../assets/images/beimeivip/UMLspan.png) no-repeat;
}
.syllabus-cell .syl-item{
	font-size: 14px;
	margin-bottom: 5px;
}
.syllabus-cell .syl-grid{
	margin-bottom: 20px;
}
.syllabus-cell .text-org{
	color: #FF602A;
}
.syl-head{
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
.syl-head .syl-title{
	font-size: 18px;
	font-weight: bold;
}
.syl-head .syl-attr{
	margin-left: auto;
	color: #999;
	font-size: 12px;
}
.syl-tag{
	padding: 0 15px;
	height: 24px;
	line-height: 24px;
	background: #FFDDC7;
	border-radius: 12px;
	margin-left: 15px;
	color: #FE6225;
	font-size: 12px;
}
.syl-arrow{
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #4795ff;
}
.active .syl-arrow{
	margin-bottom: 30px;
}
.syl-arrow .arrow{
	background: url(../../assets/images/beimeivip/down.png) no-repeat;
	width: 14px;
	height: 14px;
	display: inline-block;
	margin-left: 6px;
}
.active .syl-arrow .arrow{
	transform: rotateZ(180deg);
}

.syl-list{
	position: relative;
	padding:30px 0;
	padding-left: 30px;
	margin-left: 6px;
	border-left: 1px solid #dddddd;
}
.syl-list:before{
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 20px;
	background-color: #dddddd;
	position: absolute;
	margin-left: -6px;
	left: 0;
	top: 0;
}
.syl-list::after{
	content: '';
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right:8px solid transparent;
	border-top: 12px solid #dddddd;
	position: absolute;
	bottom: 0;
	left: -9px;
}


.syl-list-cell{
	position: relative;
	font-size: 14px;
	margin-bottom: 20px;
}
.syl-list-cell:last-child{
	margin-bottom: 0;
}
.syl-list-cell .hd{
	color: #FE6225;
	font-weight: bold;
	margin-bottom: 15px;
}
.syl-list-cell .list-text{
	color: #000;
	margin-bottom: 10px;
}
.syl-list-cell:before{
	content: '';
	width: 6px;
	height: 6px;
	border-radius: 20px;
	background-color: #ffffff;
	border: 2px solid #FE6225;
	position: absolute;
	left: -35px;
	top: 4px;
	
}
.step-box77{
    height: 210px;
}






</style>
